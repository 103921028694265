.Header.Header {
  height: 50px;
  margin: 15px 0;
}

.Logo {
  height: 50px;
}

@media screen and (max-width: 530px) {
  .Header.Header {
    font-size: 22px;
  }

  .Logo {
    height: 40px;
  }
  .Header.Header {
    height: 40px;
    margin: 20px 0;
  }
}

@media screen and (max-width: 390px) {
  .Header.Header {
    font-size: 17px;
  }
}