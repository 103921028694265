
.Section.Section.Section {
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 100px 0 160px;
  background-color: white;
}


.Section.Section.Section:global(.nopadding) {
  padding: 0;
}

.Section.Section.Section:global(.nopaddingtop) {
  padding-top: 0;
}

.Section.Section.Section:global(.nopaddingbottom) {
  padding-bottom: 0;
}

.Section.Section.Section:global(.transparent) {
  background-color: rgba(0,0,0,0.4);
}
.Section.Section.Section:global(.inverted) {
  background-color: #272727;
}

.Header {
  margin-bottom: 80px;
  text-align: center;
}

.Section:global(.inverted) .Header,
.Section:global(.transparent) .Header {
  color: white;
}