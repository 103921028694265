.Link {
  cursor: pointer;
}

.Section_Section__1v4zT.Section_Section__1v4zT.Section_Section__1v4zT {
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 100px 0 160px;
  background-color: white;
}


.Section_Section__1v4zT.Section_Section__1v4zT.Section_Section__1v4zT.nopadding {
  padding: 0;
}

.Section_Section__1v4zT.Section_Section__1v4zT.Section_Section__1v4zT.nopaddingtop {
  padding-top: 0;
}

.Section_Section__1v4zT.Section_Section__1v4zT.Section_Section__1v4zT.nopaddingbottom {
  padding-bottom: 0;
}

.Section_Section__1v4zT.Section_Section__1v4zT.Section_Section__1v4zT.transparent {
  background-color: rgba(0,0,0,0.4);
}
.Section_Section__1v4zT.Section_Section__1v4zT.Section_Section__1v4zT.inverted {
  background-color: #272727;
}

.Section_Header__2lcn0 {
  margin-bottom: 80px;
  text-align: center;
}

.Section_Section__1v4zT.inverted .Section_Header__2lcn0,
.Section_Section__1v4zT.transparent .Section_Header__2lcn0 {
  color: white;
}
.Jumbotron_Button__14b6Q.Jumbotron_Button__14b6Q {
  padding: 15px 50px;
  margin-right: 50px;
}

.Jumbotron_Button__14b6Q.Jumbotron_Button__14b6Q:last-child {
  margin-right: 0px;
}

.Jumbotron_Container__1Vufu {
  position: absolute;
  top: 35%;
  width: 100%;
}

.Jumbotron_Header__oCQSn {
  font-size: 48px;
  color: white;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.Jumbotron_Wrapper__3aCo- {
  min-height: 45vh;
  position: relative;
}

@media screen and (max-width: 767px) {
  .Jumbotron_Button__14b6Q.Jumbotron_Button__14b6Q.Jumbotron_Button__14b6Q {
    margin-right: 10px;
  }

  .Jumbotron_Header__oCQSn {
    font-size: 36px;
  }
}

@media screen and (max-width: 400px) {
  .Jumbotron_Button__14b6Q.Jumbotron_Button__14b6Q {
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .Jumbotron_Header__oCQSn {
    font-size: 30px;
  }
}
.Logo_Header__3oZ_L.Logo_Header__3oZ_L {
  height: 50px;
  margin: 15px 0;
}

.Logo_Logo__dnLm1 {
  height: 50px;
}

@media screen and (max-width: 530px) {
  .Logo_Header__3oZ_L.Logo_Header__3oZ_L {
    font-size: 22px;
  }

  .Logo_Logo__dnLm1 {
    height: 40px;
  }
  .Logo_Header__3oZ_L.Logo_Header__3oZ_L {
    height: 40px;
    margin: 20px 0;
  }
}

@media screen and (max-width: 390px) {
  .Logo_Header__3oZ_L.Logo_Header__3oZ_L {
    font-size: 17px;
  }
}
.Topbar_Wrapper__X9qd_ {
  position: fixed;
  width: 100%;
  z-index: 100;
  background: white;
  max-height: 80px;
  overflow: hidden;
  transition: max-height 0.2s linear;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.08);
}

.Topbar_Wrapper__X9qd_.expanded {
  max-height: 200px;
}

.Topbar_Hamburger__NY4tj.Topbar_Hamburger__NY4tj {
  position: absolute;
  right: 1rem;
  top: 40px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 999;
  background: none;
}

@media screen and (min-width: 768px) {
  .Topbar_Hamburger__NY4tj.Topbar_Hamburger__NY4tj {
    display: none;
  }
}

.Topbar_Hamburger__NY4tj i {
  font-size: 24px;
}

.Topbar_Hamburger__NY4tj.Topbar_Hamburger__NY4tj:focus, .Topbar_Hamburger__NY4tj.Topbar_Hamburger__NY4tj:hover {
  background: none;
}
.FooterSection_Footer__3zrJq * {
  font-weight: bold;
  font-style: normal;
  color: #bfbfbf;
}

.FooterSection_Footer__3zrJq a:hover {
  color: #e96656;
}

@media screen and (max-width: 767px) {
  .FooterSection_Footer__3zrJq.FooterSection_Footer__3zrJq.FooterSection_Footer__3zrJq.FooterSection_Footer__3zrJq.FooterSection_Footer__3zrJq.FooterSection_Footer__3zrJq.FooterSection_Footer__3zrJq * {
    text-align: center;
  }
}

.FooterSection_Icon__3San9.FooterSection_Icon__3San9.FooterSection_Icon__3San9 {
  color: #e96656;
}

.FooterSection_Header__14OMa.FooterSection_Header__14OMa.FooterSection_Header__14OMa {
  text-transform: uppercase;
  font-size: 16px;
  color: #555;
}

.FooterSection_SocialMedia__3Zmcz.FooterSection_SocialMedia__3Zmcz.FooterSection_SocialMedia__3Zmcz {
  color: #e96656;
  font-size: 36px;
}

.FooterSection_Copyright__Xznro {
  display: block;
  color: #555555;
  margin: 80px auto 0;
  padding-bottom: 6px;
  text-align: center;
}

.App_Wrapper__y-F3q:after {
  content: "";
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: -1;
  background: url(/static/media/bg@1x.9861ae54.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
  .App_Wrapper__y-F3q:after { 
    background: url(/static/media/bg@2x.dafbeb91.jpg);
  }
}
h1, h2, h3, h4, h5 {
  color: #404040;
  font-family: 'Montserrat', sans-serif;
}

p {
  color: #808080;
  font-size: 1.1rem;
}

.inverted p {
  color: #ababab;
}

.ui.secondary.menu .active.item {
  background: none;
  color: #e96656;
  border-bottom: 1px solid #e96656;
  border-radius: 0;
}
