
.Wrapper:after {
  content: "";
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: -1;
  background: url('./assets/bg@1x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

@media screen and (min-resolution: 2dppx) {
  .Wrapper:after { 
    background: url('./assets/bg@2x.jpg');
  }
}