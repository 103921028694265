.Footer * {
  font-weight: bold;
  font-style: normal;
  color: #bfbfbf;
}

.Footer a:hover {
  color: #e96656;
}

@media screen and (max-width: 767px) {
  .Footer.Footer.Footer.Footer.Footer.Footer.Footer * {
    text-align: center;
  }
}

.Icon.Icon.Icon {
  color: #e96656;
}

.Header.Header.Header {
  text-transform: uppercase;
  font-size: 16px;
  color: #555;
}

.SocialMedia.SocialMedia.SocialMedia {
  color: #e96656;
  font-size: 36px;
}

.Copyright {
  display: block;
  color: #555555;
  margin: 80px auto 0;
  padding-bottom: 6px;
  text-align: center;
}