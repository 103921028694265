.Wrapper {
  position: fixed;
  width: 100%;
  z-index: 100;
  background: white;
  max-height: 80px;
  overflow: hidden;
  transition: max-height 0.2s linear;
  box-shadow: 0px 5px 11px 0px rgba(50, 50, 50, 0.08);
}

.Wrapper:global(.expanded) {
  max-height: 200px;
}

.Hamburger.Hamburger {
  position: absolute;
  right: 1rem;
  top: 40px;
  transform: translateY(-50%);
  z-index: 999;
  background: none;
}

@media screen and (min-width: 768px) {
  .Hamburger.Hamburger {
    display: none;
  }
}

.Hamburger i {
  font-size: 24px;
}

.Hamburger.Hamburger:focus, .Hamburger.Hamburger:hover {
  background: none;
}