.Button.Button {
  padding: 15px 50px;
  margin-right: 50px;
}

.Button.Button:last-child {
  margin-right: 0px;
}

.Container {
  position: absolute;
  top: 35%;
  width: 100%;
}

.Header {
  font-size: 48px;
  color: white;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.Wrapper {
  min-height: 45vh;
  position: relative;
}

@media screen and (max-width: 767px) {
  .Button.Button.Button {
    margin-right: 10px;
  }

  .Header {
    font-size: 36px;
  }
}

@media screen and (max-width: 400px) {
  .Button.Button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .Header {
    font-size: 30px;
  }
}