h1, h2, h3, h4, h5 {
  color: #404040;
  font-family: 'Montserrat', sans-serif;
}

p {
  color: #808080;
  font-size: 1.1rem;
}

.inverted p {
  color: #ababab;
}

.ui.secondary.menu .active.item {
  background: none;
  color: #e96656;
  border-bottom: 1px solid #e96656;
  border-radius: 0;
}